import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import ServicePage from "../Pages/ServicePage/ServicePage";
import AboutPage from "../Pages/AboutPage/AboutPage";
import NoPage from "../NoPage/NoPage";
import KnowYourMeat from "../Pages/ServicePage/KnowYourMeat";

const BodyRoute = () => {
  return (
    <>
      <div className="bodysection">
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/services" exact element={<ServicePage />}></Route>
          <Route path="/services/knowyourmeat" exact element={<KnowYourMeat/>}></Route>
          <Route path="/about" exact element={<AboutPage />}></Route>

          <Route path="*" exact element={<NoPage />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default BodyRoute;
