import React, { useState, useEffect } from "react";
import "./productsection.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
// import StringData from "../../String.json";
import { Data, siteIndex } from "../../WebsiteData";
const UsedData = Data[siteIndex];

const ProductSection = () => {
  const [data, setData] = useState(UsedData.productsection.productdata);

  useEffect(() => {
    if (
      UsedData &&
      UsedData.productsection &&
      UsedData.productsection.productdata
    ) {
      setData(UsedData.productsection.productdata);
    }
  }, [data]);

  return (
    <>
      <div className="container-fluid product-main" id="product-main">
        <div className="container">
          <div className="top-icon">
            <div className="hr-line"></div>
            <div className="circle">
              <img src={UsedData.productsection.icon} alt="seeds" />
            </div>
            <div className="hr-line"></div>
          </div>
          <div className="product-content">
            <div className="product-text">
              <h1>Our New Products</h1>
              <p>{UsedData.productsection.description}</p>
            </div>

            <Splide
              className="row product-card mt-5 gap-2"
              options={{
                perPage: 4,
                rewind: true,
                gap: "1rem",
                breakpoints: {
                  1024: {
                    perPage: 3,
                    rewind: true,
                    gap: "2rem",
                  },
                  768: {
                    perPage: 2,
                    rewind: true,
                    gap: "2rem",
                  },
                  480: {
                    perPage: 1,
                    rewind: true,
                    gap: "2rem",
                  },
                },
              }}
            >
              {data.map((items) => (
                <SplideSlide
                  key={items.seedID}
                  className="card col-lg-3 col-md-4 col-sm-6 p-2 pb-2"
                  style={{ height: "auto" }}
                >
                  <a
                    href={items.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none text-black"
                  >
                    <img
                      src={items.seedimage}
                      className="card-img-top object-fit-cover"
                      alt={items.seedname}
                      style={{ height: "250px" }}
                    />
                    <div
                      className="card-body text-start"
                      style={{ height: "auto" }}
                    >
                      <h5 className="card-title">{items.seedname}</h5>
                      <p className="card-text">{items.seedcategory}</p>
                      <p className="card-text">{items.seedprice}</p>
                      <a
                        href={items.link}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-success"
                      >
                        Add to Cart
                      </a>
                    </div>
                  </a>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection;
